import {Container, Row, Col} from 'react-bootstrap';
import CodeText from './CodeText';
import { Box } from '@mui/material';

const Code = (props:{showCode:string, showText:string}):any => {

    const CodeSwitch = (props:{showCode:string, showText:string}):any => {
        if(props.showCode!==null && props.showText!==null)
        {
            return(
            <Container fluid style={{paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px",}}>
                <Box 
                    sx={{
                        paddingLeft:0,
                        paddingTop: "10px",
                    }}>
                <Row>
                    <Col>
                        <CodeText showCode={props.showCode} showText={props.showText}></CodeText>
                    </Col>
                </Row>
                </Box>
            </Container>
            )
        }
    }

    return (
        <CodeSwitch showCode={props.showCode} showText={props.showText}></CodeSwitch>
    )
    
}

export default Code;