import {Container, Row, Col} from 'react-bootstrap';
import { AiOutlineBranches } from "react-icons/ai"
import styles from './header.module.css'
import about from './about.json'

const object:string = about.object
const author:string = about.author

const About = (props:{changeAbout:any, resize:any}):any=> {
    
    return(
        <Container fluid style={{paddingLeft:"0px", paddingRight:"0px", paddingTop:"0px"}}>
        <div className={styles.just}>
                <span className={styles.marker}>
                    <p onClick={() => props.changeAbout(author)}>
                        [Author]
                    </p>
                </span>
                <span className={styles.marker}>
                    <p onClick={() => props.changeAbout(object)}>
                        [Philosophy]
                    </p>
                </span>
                <span className={styles.marker}>
                    <p onClick={() => props.resize()}>
                        [Image]
                    </p>
                </span>
            </div>
        </Container>
        
    )
}

export default About;