import { TypeAnimation } from 'react-type-animation';

import { Box } from '@mui/material';

import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col} from 'react-bootstrap';
import styles from './code.module.css'

const CodeText = (props:{showCode:string, showText:string}):any => {
    return(
    <>
    <Container style={{paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px",}}>
        <Row>
        <div>
            <Col>
                    <Box sx={{ width:"100%",}}>
                        <div className={styles.codeText}>
                            <pre>
                                <code>
                                <TypeAnimation
                                    style={{ whiteSpace: 'pre-line', display: 'block' }}
                                    sequence={[props.showCode]}
                                    speed={99}
                                />
                                </code>
                            </pre>
                                
                        </div>
                    </Box>
                    <Box sx={{ width:"100%"}}>
                        <div className={styles.text}>
                            <pre>
                                <code>
                                <TypeAnimation
                                    style={{ whiteSpace: 'pre-line', display: 'block' }}
                                    sequence={[props.showText]}
                                    speed={99}
                                />
                                </code>
                            </pre>
                        </div>
                    </Box>
            </Col>
            </div>
        </Row>
    </Container>
    </>
    )
}

export default CodeText;