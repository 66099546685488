import { useState, useRef, useEffect } from 'react';
import contentsList from './contents'
import Contents from './ContentsCard'
import { AiOutlineBranches } from "react-icons/ai"
import { Box } from '@mui/material';

import CardMedia from '@mui/material/CardMedia';

import {Container, Row, Col} from 'react-bootstrap';
import styles from './Card.module.css'
import './styles.css';
import headerStyles from './header.module.css'

import Code from './Code'
import About from './About'
import about from './about.json'


const object:string = about.object

const Point = ():any => {

    const [showCode, setShowCode] = useState(">")
    const [showText, setShowText] = useState(object)

    const [size, setSize] = useState(false)
    const [col, setCol] = useState(5)


    const [clicked, setClicked] = useState<number|null>(null)


    const changeText = (data:any) => {
        setShowCode(data.code)
        setShowText(data.ref)
    }

    const changeAbout = (info:string) => {
        setClicked(null)
        setShowCode(">")
        setShowText(info)
    } 

    const resize = () => {
        setSize(!size)
        if (size){
            setCol(5)
        }
        else{
            setCol(8)
        }
    }


    return (
        <>
        <Container fluid style={{ paddingLeft: "20px", paddingRight: "20px"}}>
                <Row>
                    <Col xs={12} sm={col} className={styles.pane1}>
                            <header id={headerStyles.header}>
                                <div className={headerStyles.headerContent}>
                                        <p> Learning GHPython</p>
                                </div>
                            </header>
                        
                        {contentsList.map((data:any, i:number) => {
                            return(
                                <Contents data={data} i={i} changeText={changeText} clicked={clicked} setClicked={setClicked}></Contents>)
                        })}
                        <footer id={headerStyles.footer}>
                            <p className={styles.deploy}>
                                    <About changeAbout={changeAbout} resize={resize}></About>
                            </p>
                        </footer>
                    </Col>
                    <Col xs={12} sm={12-col} className={styles.pane2}>
                        <Code showCode={showCode} showText={showText}></Code>
                    </Col>
                </Row>
        </Container>
    </>
    )
}

export default Point;