import { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import {MdDownloadForOffline} from "react-icons/md";

import CardMedia from '@mui/material/CardMedia';

import {Container, Row, Col} from 'react-bootstrap';
import styles from './Card.module.css'
import './styles.css';

const ContentsCard = (props:{data:any, i:number, domRef:any, isVisible:any, changeText:any, clicked:any, setClicked:any}):any => {
    return(
    <div key={props.i} className={`fade-in-section ${props.isVisible ? 'is-visible' : ''}`} ref={props.domRef} >
    <Container fluid style={{paddingLeft: "0px", paddingRight: "0px",paddingTop:"10px", paddingBottom: "0px" }} onClick={()=>props.changeText(props.data)}>
        <Box 
            className={styles.shadow}
            onClick={() =>props.setClicked(props.i)} 
            sx={{                
                '&:hover': {
                    color: "red",
                    opacity:0.8,
                },
                borderLeft: props.clicked===props.i ? 2 : '',
                color: props.clicked===props.i ? "red" : "black",
                borderStyle: props.clicked===props.i ? "red" : "black",
            }}>
            
            <Row >
                <Col sm={2}>
                    <div className={styles.content} >
                            <p className={styles.title}>
                                {props.data.title}
                            </p>
                                <a className={styles.detail} href={props.data.file}><MdDownloadForOffline/></a>
                    </div>
                </Col>
                <Col sm={10} >
                        <CardMedia
                            component="img"
                            height="100%"
                            image={props.data.img}
                            sx={{ padding: "10px", objectFit: "contain" }}
                        />
                </Col>
            </Row>
            
        </Box>
    </Container>
    </div>
    )
}

const Contents = (props:{data:any, i:number, changeText:any, clicked:any, setClicked:any}):any => {
    const [isVisible, setVisible] = useState(false);
    const domRef:any = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
    }, []);

        return(
            <div>
                <ContentsCard 
                    data={props.data} 
                    i={props.i} 
                    domRef={domRef} 
                    isVisible={isVisible} 
                    changeText={props.changeText}
                    clicked={props.clicked}
                    setClicked={props.setClicked}
                    ></ContentsCard>
            </div>
        )
}

export default Contents;