import Point from './Point'
import styles from './main.module.css'


const Main = () => {

    return (
        <div className={styles.main}>
            <Point></Point>
        </div>
    );
}

export default Main