const contentsList = [
    {
        "title": "Circle",
        "difficulty": "難易度 ★☆☆",
        "img": "./circle/Circle.png",
        "file": "./circle/Circle.gh",
        "detail":"ライノライブラリを触ってみる",
        "code": `############
        #サンプルコード#
        ############

        import Rhino.Geometry as rg #ライノセラスのライブラリを取り込む\n\ncenter = rg.Point3d(0, 0, 0) #中心点作成\nradius = 100 #半径\n\nresult = rg.Circle(center, radius) #円作成`,
        "ref": `[ポイント]
        ・rg.Point(x座標,y座標,z座標) -> 点作成
        ・rg.Circle(中心点, 半径) -> 円作成`
    },
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    {
        "title":"Line",
        "difficulty": "難易度 ★☆☆",
        "img": "./line/Line.png",
        "file": "./line/Line.gh",
        "detail":"ライノライブラリを触ってみる",
        "code": `############
        #サンプルコード#
        ############
        import Rhino.Geometry as rg #ライノセラスのライブラリを取り込む\n\nstart_point = rg.Point3d(0,0,0) #始点\nend_point = rg.Point3d(10,10,0) #終点\n\nresult = rg.Line(start_point, end_point) #Lineを引く`,
        "ref": `[ポイント]
        ・rg.Point(x座標,y座標,z座標) -> 点作成
        ・rg.Line(始点, 終点) -> 線作成`
    },
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    {
        "title":"for-1",
        "difficulty": "難易度 ★☆☆",
        "img": "./for_1/for_1.png",
        "file": "./for_1/for_1.gh",
        "detail":"for文を触ってみる",
        "code": `############
        #サンプルコード#
        ############\n\nimport Rhino.Geometry as rg #ライノセラスのライブラリを取り込む\n\nresult = list() #空のリスト作成\n\nfor i in range(num):\n\n　　　　print("{}回目の処理".format(i)) #なくても良い \n\n　　　　x = i*10 #x座標\n　　　　y = i*5 #y座標\n　　　　z = 0 #z座標 \n\n　　　　print("x:", x) #なくても良い\n\n　　　　point = rg.Point3d(x,y,z) #ポイント作成\n　　　　result.append(point) #ポイントをリストに格納`,
        "ref": `[ポイント]
        ・for(i in range(num)):処理A -> num回処理Aを繰り返す。
        ・rg.Point(x座標,y座標,z座標) -> 点作成`
    },
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    {
        "title":"for-2",
        "difficulty": "難易度 ★☆☆",
        "img": "./for_2/for_2.png",
        "file": "./for_2/for_2.gh",
        "detail":"for文を触ってみる",
        "code": `############
        #サンプルコード#
        ############\n\nimport Rhino.Geometry as rg #ライノセラスのライブラリを取り込む\n\nresult = list() #空のリスト作成\n\nfor i in range(num):\n　　　　for j in range(num):\n　　　　　　　　print("{}行{}列回目の処理".format(j,i)) #なくても良い\n\n　　　　　　　　x = i*10 #x座標\n　　　　　　　　y = j*5 #y座標\n　　　　　　　　z = 0 #z座標\n\n　　　　　　　　print("x:", x) #なくても良い\n　　　　　　　　print("y:", y) #なくても良い\n\n　　　　　　　　point = rg.Point3d(x,y,z) #ポイント作成\n\n　　　　　　　　result.append(point) #ポイントをリストに格納`,
        "ref": `[ポイント]
        ・for(i in range(num)):処理A -> num回処理Aを繰り返す。
        ・rg.Point(x座標,y座標,z座標) -> 点作成`
    },
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    {
        "title":"Block",
        "difficulty": "難易度 ★☆☆",
        "img": "./block/block.png",
        "file": "./block/block.gh",
        "detail":"for文を触ってみる",
        "code": `############
        #サンプルコード#
        ############
            
        import Rhino.Geometry as rg #ライノセラスのライブラリを取り込む

        result = list() #空のリスト作成
        
        for i in range(num):
        　　　　for j in range(num):
        　　　　　　　　if (i+j)%2==0:
                    
        　　　　　　　　　　　　x = i * (width/2) #x座標
        　　　　　　　　　　　　y = j * (height) #y座標
        　　　　　　　　　　　　z = 0 #z座標
                    
        　　　　　　　　　　　　point = rg.Point3d(x,y,z) #ポイント作成
        　　　　　　　　　　　　plane = rg.Plane(point, rg.Vector3d.XAxis, rg.Vector3d.YAxis) #平面作成
        　　　　　　　　　　　　block = rg.Rectangle3d(plane, width, height) #ブロック作成
        　　　　　　　　　　　　result.append(block) #ブロック格納`,
        "ref": `[ポイント]
        ・for(i in range(num)):処理A -> num回処理Aを繰り返す。
        ・if(条件A):処理A -> 条件Aを満たす場合、処理Aを実行する。
        ・rg.Point(x座標,y座標,z座標) -> 点作成
        ・rg.Plane(ポイント, x方向, y方向) -> 平面作成
        ・rg.Rectangle3d(平面, 幅, 高さ) -> 矩形作成`
    },
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    {
        "title":"Stair",
        "difficulty": "難易度 ★☆☆",
        "img": "./stairs/Stairs.png",
        "file": "./stairs/Stairs.gh",
        "detail":"for文を触ってみる",
        "code": `############
        #サンプルコード#
        ############

        import Rhino.Geometry as rg #ライノセラスのライブラリを取り込む

        result = []
        
        x = 0
        z = 0
        
        for i in range(step_num*2):
        　　　　if i == 0: #繰り返し処理の最初一回のみ原点を入力
        　　　　　　　　result.append(rg.Point3d(0,0,0))
        　　　　else: #二回目以降
        　　　　　　　　if i%2 == 0: #x座標
        　　　　　　　　　　　　x += depth
        　　　　　　　　else: #y座標
        　　　　　　　　　　　　z += height
        　　　　　　　　point = rg.Point3d(x,0,z)
        　　　　　　　　result.append(point)`,
        "ref":`[ポイント]
        ・for(i in range(num)):処理A -> num回処理Aを繰り返す。
        ・if(条件A):処理A else:処理B -> 条件Aを満たす場合、処理Aを実行し、条件Aを満たさない場合処理Bを実行する。
        ・rg.Point(x座標,y座標,z座標) -> 点作成`
    },
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    {
        "title":`Closest
        Point`,
        "difficulty": "難易度 ★☆☆",
        "img": "./closest_point/ClosestPoint.png",
        "file": "./closest_point/ClosestPoint.gh",
        "detail":"for文を触ってみる",
        "code": `############
        #サンプルコード#
        ############

        import Rhino.Geometry as rg #ライノセラスのライブラリを取り込む
        
        result = list()  #空のリスト作成
        for i in range(20): 
        　　　　for j in range(20):
        　　　　 　　　　point = rg.Point3d(i, j, 0)

        　　　　 　　　　dist = 1000 #仮の値を代入
        　　　　 　　　　for anchor in anchors:
        　　　　 　　　　 　　　　temp_dist = point.DistanceTo(anchor) #アンカーポイントとの距離測定
        　　　　 　　　　 　　　　if temp_dist < dist:
        　　　　 　　　　 　　　　 　　　　dist = temp_dist #temp_distがdistより小さい場合、distを更新
                        
        　　　　 　　　　circle = rg.Circle(point, dist/50)
        　　　　 　　　　result.append(circle)`,
        "ref":`[ポイント]
        ・for(i in range(num)):処理A -> num回処理Aを繰り返す。
        ・rg.Point(x座標,y座標,z座標) -> 点作成
        ・rg.Plane(ポイント, x方向, y方向) -> 平面作成
        ・a += b -> a = a+b の略`
    },
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    {
        "title":"Louver",
        "difficulty": "難易度 ★☆☆",
        "img": "./louver/Louver.png",
        "file": "./louver/Louver.gh",
        "detail":"for文を触ってみる",
        "code": `############
        #サンプルコード#
        ############

        import Rhino.Geometry as rg #ライノセラスのライブラリを取り込む
        import math
        
        angle = 0 #回転角度
        diff_angle = max_angle / num #差分角度
        z_axis = rg.Vector3d.ZAxis #z軸
        
        result = list() #最終出力
        for i in range(num):
        　　　　point = rg.Point3d(i*pitch, 0, 0) #planeポイント
        　　　　plane = rg.Plane(point, z_axis) #plane作成
        　　　　plane.Rotate(angle, z_axis, point) #plane回転
        　　　　result.append(plane) #plane格納
        　　　　angle += math.radians(diff_angle) #角度調整`,
        "ref":`[ポイント]
        ・for(i in range(num)):処理A -> num回処理Aを繰り返す。
        ・rg.Point(x座標,y座標,z座標) -> 点作成
        ・rg.Plane(ポイント, x方向, y方向) -> 平面作成
        ・a += b -> a = a+b の略`
    },
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    {
        "title":"Tree",
        "difficulty": "難易度 ★☆☆",
        "img": "./tree/Tree.png",
        "file": "./tree/Tree.gh",
        "detail":"classとフラクタル",
        "code": `############
        #サンプルコード#
        ############

        import Rhino.Geometry as rg #ライノセラスのライブラリを取り込む
        import random as rnd #ライブラリを取り込む
        import copy #ライブラリを取り込む
        import math #ライブラリを取り込む


        class Tree:
        　　　　def __init__(self, branch_num, iter):
        　　　　　　　　self.branch = [[rg.Line(rg.Point3d(0,0,0), rg.Point3d(0,10,0))]] #生成されたブランチ(Line)を格納
        　　　　　　　　self.branch_num = branch_num #枝分かれの数
        　　　　　　　　self.iter = iter #世代数
                
        　　　　def create_branch(self):
        　　　　　　　　for j in range(self.iter):
        　　　　　　　　　　　　generated_branch = []
        　　　　　　　　　　　　for i in range(self.branch_num):
        　　　　　　　　　　　　　　　　for edge_branch in self.branch[-1]:
        　　　　　　　　　　　　　　　　　　　　start_pt, end_pt = edge_branch.PointAt(0), edge_branch.PointAt(1) #ブランチの始点,終点取得
        　　　　　　　　　　　　　　　　　　　　vec = rg.Vector3d(end_pt.X-start_pt.X, end_pt.Y-start_pt.Y, 0) #ベクトル化
        　　　　　　　　　　　　　　　　　　　　new_vec = copy.copy(vec) #複製
                            
        　　　　　　　　　　　　　　　　　　　　degree = rnd.randint(-45,45) #-45°から45°間のランダム値作成
        　　　　　　　　　　　　　　　　　　　　radian = math.radians(degree) #度からラジアンへ
        　　　　　　　　　　　　　　　　　　　　new_vec.Rotate(radian, rg.Vector3d.ZAxis) #ベクトルを回転
                            
        　　　　　　　　　　　　　　　　　　　　new_start_pt = end_pt #新しいブランチの始点
        　　　　　　　　　　　　　　　　　　　　new_end_pt = new_start_pt + new_vec * 0.7 #新しいブランチの終点
                            
        　　　　　　　　　　　　　　　　　　　　new_branch = rg.Line(new_start_pt, new_end_pt) #新しいブランチ作成
        　　　　　　　　　　　　　　　　　　　　generated_branch.append(new_branch) #リストに格納
        　　　　　　　　　　　　self.branch.append(generated_branch) #リストに格納
                    
        tree = Tree(branch_num, iter)
        tree.create_branch()
        result = tree.branch`,
        "ref":`[ポイント]
        ・class A -> クラスAを作成する。
        ・rg.Point(x座標,y座標,z座標) -> 点作成`
    },
    {
        "title": `Read
        Json`,
        "difficulty": "難易度 ★☆☆",
        "img": "./read_json/ReadJson.png",
        "file": "./read_json/ReadJson.zip",
        "detail":"ライノライブラリを触ってみる",
        "code": `############
        #サンプルコード#
        ############

        import json

        with open(file) as f:
        　　　　d = json.load(f)
            
        　　　　#apple取り出し例
        　　　　apple_total_price = d["apple"]["price"] * d["apple"]["num"]
            
        　　　　#全くだもの一括取り出し例
        　　　　all_total_price = []
        　　　　for fruit in d.keys():
        　　　　　　　　total_price = d[fruit]["price"] * d[fruit]["num"]
        　　　　　　　　all_total_price.append("{}の合計金額は{}円です。".format(fruit, total_price))`,
        "ref": `[ポイント]
        ・パス指定でjsonファイルをGHで読み込むことができる。`
    },
]

export default contentsList