import './App.css';
import Main from "./components/Main"

function App() {
  return (
    <div>
        <Main ></Main>
    </div>
  );
}

export default App;